@use 'sass:math';

$brandblack: #000;
$white: #fff;
$neonpink: #ff00b4;
$transparent: transparent;
$fg: $brandblack;
$bg: $white;
$font-stack-header: 'Nandos Hand Alt', serif;
$menu-max-width: 87.5rem;
$menu-inner-max-width: 70rem;
$desktop-filter-max-width: 18.75rem;
$page-width: 63.75rem;
$menu-height: 3.75rem;
$max-width: 90rem;
$header-height: 3.75rem;
$footer-height: 25rem;
$cookie-height: 8rem;
$cookie-mobile-height: 11.5rem;
$topbanner-height: 20.25rem;
$topbanner-mobile-height: 40vh;
$footer-border: #181818;
$footer-background: #1a1a1a;
$oldmobile: 320px;
$mobile: 767px;
$tablet: 1024px;

@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  src: url('../../fonts/icomoon/icomoon.ttf');
}

@mixin font-size($size-value: 1.6, $line-height: 1, $letter-spacing: 0) {
  font-size: #{math.div($size-value, 10)}rem;

  line-height: #{$line-height};

  @if $letter-spacing !=0 {
    letter-spacing: #{math.div($letter-spacing, 100)}em;
  }
}

@mixin oldmobile {
  @media screen and (max-width: $oldmobile) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin slanted-headline {
  @include font-size(25, 1.1);
  display: inline-block;
  font-family: $font-stack-header;
  font-weight: normal;
  margin: 0 auto;
  max-width: 10.625rem;
  position: relative;
  transform: rotate(-3deg);

  &::after {
    background: url('../../assets/images/line.svg') no-repeat center center;
    background-size: 100% 100%;
    bottom: -15%;
    content: '';
    height: 0.625rem;
    left: calc(50% - 3.125rem);
    position: absolute;
    width: 6.25rem;
  }
}

footer.footer {
  bottom: 0;
  color: $bg;

  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-top: 9.3rem;
  position: relative;
  right: 0;
  z-index: 3;

  &::before {
    background: $fg;
    box-sizing: border-box;
    content: '';
    height: 9rem;
    left: 0;
    position: absolute;
    right: -0.625rem;
    top: 5rem;
    transform: rotate(-3deg);
  }

  nav {
    background: $fg;
    height: auto;
    min-height: $footer-height - 1.785rem;
    position: static;
    top: auto;

    & > ul {
      margin: 0 auto;
      max-width: 90rem;
      padding: 0;
      text-align: center;

      & > li {
        @media (max-width: 1199px) {
          border-bottom: 1px solid $footer-border;
          cursor: pointer;
          display: block;
          padding: 0 1.785rem 1.25rem;
          width: auto;

          &.open {
            background: $footer-border;
            transition: background 250ms ease-out;

            .fLink {
              &::after {
                content: '-';
              }
            }
          }

          .fLink {
            margin-bottom: 0.625rem;
            text-align: left;

            &::before {
              display: none;
            }

            &::after {
              color: $neonpink;
              content: '+';
              position: absolute;
              margin-left: 10px;
            }
          }

          ul {
            display: none;

            &.open {
              display: block;
              margin-bottom: 1.25rem;
              text-align: left;

              a {
                transition: color 0.2s ease-in-out;

                &:hover {
                  color: $neonpink;
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          padding: 0 1.785rem;
        }

        display: inline-block;
        list-style-type: none;
        padding: 0;
        position: relative;
        vertical-align: top;
        width: 15%;

        &:nth-child(1) {
          .fLink {
            &::before {
              transform: rotate(8deg);
            }
          }
        }

        &:nth-child(4),
        &:nth-child(2) {
          .fLink {
            &::before {
              transform: rotate(4deg);
            }
          }
        }

        &:nth-child(3) {
          .fLink {
            &::before {
              transform: rotate(-2deg);
            }
          }
        }

        &:nth-child(5) {
          .fLink {
            &::before {
              transform: rotate(6deg);
            }
          }
        }

        &:nth-child(6) {
          .fLink {
            &::before {
              transform: rotate(9deg);
            }
          }
        }

        ul {
          padding: 0;

          li {
            list-style-type: none;
            margin-bottom: 0.3rem;
            text-align: center;
          }
        }

        .fLink {
          @media (max-width: 74.38rem) {
            margin-top: 0;
            padding-top: 1.25rem;
            cursor: pointer;
          }

          @media (max-width: 48rem) {
            padding-bottom: 0.31rem;
            padding-top: 0.93rem;
          }

          color: $bg;
          cursor: default;
          display: block;
          margin-bottom: 1.8rem;
          margin-top: 1.25rem;
          position: relative;
          text-align: center;
          text-transform: uppercase;

          &::before {
            background: url('../../assets/images/line.svg') no-repeat center
              center;
            background-size: 3.12rem 0.312rem;
            bottom: -1.25rem;
            content: '';
            height: 0.625rem;
            left: 0;
            position: absolute;
            right: 0;
          }
        }

        a {
          color: $bg;
          font-size: 1.1rem;
          text-decoration: none;
        }
      }
    }
  }

  .bottom {
    @include font-size(8.75, 1);
    background: $footer-background url('../../assets/images/footer-bg.svg') top
      left repeat-x;
    background-size: 320px 12px;
    padding: 3.75rem 1.875rem 1.875rem;
    text-align: center;

    h4 {
      @include slanted-headline;
    }

    ul {
      display: block;
      list-style-type: none;
      margin: 1.8rem 0;
      padding: 0;
    }

    a {
      color: $bg;
    }

    li {
      display: inline;
      padding: 0;

      a {
        @include oldmobile {
          width: 2.18rem;
        }

        @include mobile {
          width: 2.5rem;
        }

        @include tablet {
          width: 2.81rem;
        }

        color: $transparent;
        display: inline-block;
        font-family: icomoon;
        height: 1.87rem;
        position: relative;
        width: 2.81rem;

        &::after {
          bottom: 0;
          color: $white;
          content: '\e932';
          font-size: 1.25rem;
          left: 0.625rem;
          position: absolute;
          right: 0.625rem;
          top: 0;
        }

        &.tw {
          &::after {
            content: '\f099';
          }
        }

        &.ig {
          &::after {
            content: '\e933';
          }
        }

        &.sc {
          &::after {
            content: '\e934';
          }
        }

        &.yt {
          &::after {
            content: '\f16a';
          }
        }

        &.sp {
          &::after {
            content: '\e908';
          }
        }
      }
    }
  }
}
