@use 'sass:math';

$brandblack: #000;
$white: #fff;
$neonpink: #ff00b4;
$transparent: transparent;
$fg: $brandblack;
$bg: $white;
$footer-border: #181818;
$footer-background: #1a1a1a;
$perired: #ce0a24;
$mercury: #e0e0e0;
$lemonherb: #96c21e;
$brownishgrey: #646464;
$neonturquoise: #14e1c1;
$charcoal: #1e2323;
$neonlime: #e6eb23;
$order-button-over: #95e8db;
$order-button: $neonturquoise;

$font-stack-header: Nandos, serif;

$menu-max-width: 87.5rem;
$menu-inner-max-width: 70rem;
$desktop-filter-max-width: 18.75rem;
$page-width: 63.75rem;
$menu-height: 3.75rem;
$max-width: 90rem;
$header-height: 3.75rem;
$footer-height: 25rem;
$cookie-height: 8.19rem;
$cookie-mobile-height: 11.6rem;
$topbanner-height: 20.25rem;
$topbanner-mobile-height: 40vh;

$oldmobile: 320px;
$mobile: 767px;
$tablet: 1024px;
$tabletportrait: 768px;

@mixin oldmobile {
  @media screen and (max-width: $oldmobile) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin font-size($size-value: 1, $line-height: 1, $letter-spacing: 0) {
  font-size: #{math.div($size-value, 16)}rem;

  line-height: #{$line-height};

  @if $letter-spacing !=0 {
    letter-spacing: #{math.div($letter-spacing, 100)}em;
  }
}

@mixin motion {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}

header.header {
  background: $bg;
  height: $menu-height;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 10;
  font-weight: 600;

  .mainmenu & {
    z-index: 20;
  }

  nav {
    margin: 0 auto;
    max-width: $menu-max-width;
    text-align: center;

    & > ul {
      background: $bg;
      display: inline-block;
      margin: 0.3125rem 0 0;
      padding: 0;
      position: relative;
      text-align: center;
      width: auto;

      &:not(.endgroup) {
        @media (max-width: 1199px), (max-device-width: 768px) {
          bottom: 0;
          display: none;
          left: 0;
          margin: 0;
          position: fixed;
          right: 0;
          top: $menu-height;

          &.open {
            border-top: 0.19rem solid $perired;
            display: block;

            ul {
              &.open {
                border: 0;
                display: block;
                position: static;
                top: auto;
              }
            }

            & > li {
              border-bottom: 0.0625rem solid $mercury;
              display: block;
              margin: 0 auto;
              max-width: 43.75rem;
              padding: 1.25rem 0 0 1.25rem;
              text-align: left;

              [aria-haspopup='true'] {
                &::after {
                  background: url('../../assets/images/down.svg') no-repeat
                    center center;
                  border: 0;
                  content: '';
                  height: 3.125rem;
                  left: auto;
                  opacity: 1;
                  position: absolute;
                  right: 0;
                  top: 0.625rem;
                  transform: rotate(0deg);
                  transition: transform 250ms ease-out;
                  width: 3.125rem;
                }

                &::before {
                  bottom: 0;
                  content: '';
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
                }

                &.open {
                  &::after {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }

        margin: 1.25rem 0 0;
      }

      &.endgroup {
        @include tablet {
          margin-top: 0.625rem;
          transform: none;
        }

        float: right;
        position: static;
        top: 0.625rem;
        transform: translateY(0.375rem);

        ul {
          display: block;
          margin-top: 0.313rem;
          right: 0;
          top: calc(#{$menu-height} - 1rem);
        }

        .login {
          font-size: 0;

          &::before {
            color: $lemonherb;
            line-height: 1;
            margin: 0;
            top: 0.25rem;
          }
        }

        .mobileOpener {
          @media (max-width: 1199px), (max-device-width: 768px) {
            display: block;
          }

          appearance: none;
          background: $transparent;
          border: 0;
          display: none;
          height: 2.5rem;
          position: relative;
          top: -0.5rem;
          width: 2.5rem;

          &::before,
          &::after {
            border-bottom: 0.125rem solid $fg;
            border-top: 0.125rem solid $fg;
            bottom: 0.875rem;
            content: '';
            left: 0.75rem;
            position: absolute;
            top: 0.875rem;
            transition: transform 0.5s ease-out;
            width: 1rem;
          }

          &::after {
            border-bottom: 0;
            bottom: auto;
            top: 1.19rem;
          }

          &.active {
            &::before {
              border-bottom: 0;
              transform: rotate(45deg);
              transform-origin: 0% 25%;
            }

            &::after {
              transform: rotate(-45deg);
              transform-origin: 55% 100%;
            }
          }
        }
      }

      .divider {
        @media (max-device-width: 768px) {
          display: none;
        }
        display: inline-block;
        height: 1.25rem;
        position: relative;
        width: 1.875rem;

        &::after {
          background: $brandblack;
          bottom: -0.3125rem;
          content: '';
          left: 0.625rem;
          position: absolute;
          top: 0.3125rem;
          width: 0.0625rem;
        }
      }
    }
  }

  h1 {
    @media screen and (max-width: 25rem) {
      background-position: left center;
      background-size: 3.125rem 7.5rem;
      height: 3.125rem;
      overflow: hidden;
      width: 1.65rem;
    }

    display: inline-block;
    float: left;
    line-height: 1;
    margin: 0 0 1.25rem 1.25rem;
    padding: 0;
    vertical-align: top;

    a {
      background: url('../../assets/images/barci.svg') no-repeat center center;
      color: $transparent;
      display: block;
      height: 3.75rem;
      width: 6.875rem;
    }
  }

  h4 {
    color: $brownishgrey;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 1.25rem 0 0.3125rem;
    text-transform: uppercase;
  }

  ul {
    display: inline;

    li {
      display: inline-block;
      list-style-type: none;
      margin: 0 0.625rem;
      position: relative;

      &.login {
        @media (max-width: 31rem) {
          display: none;
        }
      }

      &.lbutton {
        display: none;
      }

      ul {
        @media (max-width: 42.5rem), (max-device-width: 768px) {
          display: none;
        }

        background: $bg;
        border-top: 0.19rem solid $perired;
        display: block;
        margin-left: -1.25rem;
        margin-top: 0.44rem;
        opacity: 0;
        padding: 0.625rem;
        pointer-events: none;
        position: absolute;
        //top: calc(#{$menu-height});
        transition: opacity 0.5s ease-out;
        visibility: hidden;

        li {
          display: block;
          text-align: left;

          a {
            text-transform: none;
            white-space: nowrap;
          }
        }

        .divider {
          display: block;
          height: 0.0625rem;
          margin: 0.3125rem 0.625rem;
          width: auto;

          &::after {
            height: 0.0625rem;
            left: 0;
            right: 0;
            width: auto;
          }
        }

        &.open {
          opacity: 1;
          pointer-events: auto;
          transition: none;
          visibility: visible;
        }
      }
    }
  }

  li {
    a {
      @include font-size(18, 2);
      color: $fg;
      cursor: pointer;
      padding-right: 0.78rem;
      text-decoration: none;
      text-transform: uppercase;

      &.open,
      &:hover {
        color: $perired;
      }

      &::after {
        border-color: $neonturquoise transparent transparent;
        border-style: solid;
        border-width: 10px 5px 0;
        content: '';
        height: 0;
        left: -8px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: rotate(-15deg);
        width: 0;
      }

      &.open {
        &::after {
          opacity: 1;
        }
      }

      &.location,
      &.login,
      &.support {
        font-size: 0;
        padding-right: 0.3125rem;
        position: relative;
        width: 1.875rem;

        &::before {
          -webkit-font-smoothing: antialiased;
          color: $neonturquoise;
          content: '\e900';
          cursor: pointer;
          font-family: icomoon;
          font-size: 1.875rem;
          line-height: 1;
          position: relative;
          speak: none;
          text-transform: none;
          top: 0.25rem;
        }

        &.location {
          & + ul {
            @include mobile {
              left: 0;
              margin: 0;
              position: fixed;
              right: 0;
              top: 3.75rem;
              width: auto;
            }
          }
        }

        &:hover {
          &::before {
            content: '\e901';
          }
        }
      }

      &.login {
        @include font-size(18, 2);

        &::before {
          color: $charcoal;
          content: '\e902';
          line-height: 1;
          margin-right: 0.625rem;
          position: relative;
          top: 0.375rem;
        }

        &:hover {
          &::before {
            content: '\e903';
          }
        }
      }

      &.support {
        &::before {
          color: $neonpink;
          content: '\e904';
        }

        &:hover {
          &::before {
            content: '\e905';
          }
        }
      }

      &.lbutton,
      &.order {
        &:hover {
          background-color: $order-button-over;
          color: $fg;

          &::after {
            animation: bounce 0.5s infinite alternate;
          }
        }

        background: $order-button;
        display: inline-block;
        padding: 0 1.6rem 0 2.5rem;
        position: relative;

        &::before,
        &::after {
          backface-visibility: hidden;
          border-color: $neonpink transparent transparent;
          border-style: solid;
          border-width: 12px 8px 0;
          box-sizing: border-box;
          content: '';
          display: block;
          height: 0;
          left: 11px;
          opacity: 1;
          position: absolute;
          right: auto;
          top: 0;
          transform: translateY(-0.3125rem);
          width: 0;
          z-index: 2;
        }

        &::before {
          border-color: $white transparent transparent;
          transform: translateY(0);
        }

        @at-root {
          @keyframes bounce {
            0% {
              transform: translateY(-0.5rem);
            }

            100% {
              transform: translateY(-0.7rem);
            }
          }
        }
      }
    }

    form {
      @include mobile {
        width: auto;
      }

      width: 25rem;

      label {
        color: $brownishgrey;
        display: block;
        font-size: 0.875em;
        font-weight: normal;
        padding-top: 0.625rem;
        text-transform: uppercase;
      }

      input {
        appearance: none;
        border-radius: 0;
        box-sizing: border-box;
        display: inline-block;
        font-size: 0.94rem;
        padding: 0.75rem 0.625rem 0.5rem;
      }

      [type='text'] {
        @include mobile {
          width: 85%;
        }

        border: 1px solid $mercury;
        font-weight: lighter;
        margin: 0.625rem 0 1.25rem;
        width: 89%;
      }

      [type='submit'] {
        @include motion {
          transition: background 0.5s ease-in, color 0.5s ease-out;
        }

        background: $fg;
        border: 0.0625rem solid $fg;
        color: $neonlime;
        cursor: pointer;
        margin: 0;
        text-transform: uppercase;

        &:hover {
          background: $neonlime;
          color: $fg;
        }
      }

      .geo {
        background: none;
        border: 0;
        height: 1.625rem;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 20rem;
        top: 3.25rem;
        width: 1.375rem;

        &::after {
          bottom: 0;
          color: $neonpink;
          content: '\e605';
          font-family: icomoon;
          font-size: 1.5rem;
          left: 0;
          line-height: 1;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &.shorten {
        width: 18.75rem;

        [type='text'] {
          width: 86%;
        }

        .geo {
          left: 13.75rem;
          top: 4.5rem;
        }
      }
    }
  }
}
