/* The colour vars come from @nandosuk/peri-verse index.css */

.Toastify__toast-container {
  color: #000;
  width: 25rem;
}

.Toastify__toast {
  box-sizing: border-box;
  padding: 0.6rem;
  border-radius: 0.2rem;
  border: 1px solid;
  border-left-width: 4px;
}

.Toastify__toast-body {
  margin: 1rem 0;
}

.Toastify__toast-body svg {
  flex: 0 0 2rem;
}

.Toastify__close-button {
  color: #000;
}

@media only screen and (max-width: 445px) {
  .Toastify__toast-container {
    max-width: 90vw;
    left: 0;
    right: 0;
  }

  .Toastify__toast {
    margin-bottom: 1rem;
    border-radius: 0.2rem;
  }
}

@media only screen and (max-width: 480px) and (min-width: 446px) {
  .Toastify__toast-container {
    max-width: 100vw;
    left: unset;
    top: 1em;
    right: 1em;
  }

  .Toastify__toast {
    margin-bottom: 1rem;
    border-radius: 0.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .Toastify__toast-container {
    margin: 16px;
  }
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
}

.Toastify__toast--info {
  background: var(--info);
  border-color: var(--info-border);
}
.Toastify__toast--success {
  background: var(--success);
  border-color: var(--success-border);
}
.Toastify__toast--warning {
  background: var(--warning);
  border-color: var(--warning-border);
}
.Toastify__toast--error {
  background: var(--error);
  border-color: var(--error-border);
}
