$brandblack: #000;
$shade: rgba($brandblack, 0.6);
$cookie-height: 8.19rem;
$cookie-mobile-height: 11.6rem;
$oldmobile: 320px;
$mobile: 767px;
$tablet: 1024px;

@mixin motion {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}

@mixin oldmobile {
  @media screen and (max-width: $oldmobile) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

.shade-component {
  @include motion {
    transition: opacity 0.2s ease-out, z-index 0.2s ease-out;
  }
  background: $shade;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  .shaded &,
  .opencookies & {
    @include motion {
      transition: opacity 0.5s ease-out;
    }
    opacity: 1;
    pointer-events: auto;
    z-index: 11;
  }

  .filters & {
    z-index: 3;
  }

  .opencookies & {
    @include mobile {
      top: $cookie-mobile-height;
    }
    top: $cookie-height;
    z-index: 12;
  }
}
